import Vue from 'vue';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import Slick from 'vue-slick';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.component('slick', Slick);

// Register all components within the components/base dir globally
const requireComponent = require.context(
    // Look for files in the components/base directory
    '../components/base',
    // Look in subdirectories
    true,
    // Only include .vue files
    /\.vue$/
);

// For each matching file name...
requireComponent.keys().forEach((filename) => {
    // Get the component config
    const componentConfig = requireComponent(filename);
    const componentName = filename.split('/').pop().replace(/\.\w+$/, '');

    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig)
});

export default Vue;