<template>
	<section id="hero">
		<BaseBox>
				<slick ref="carousel" :options="slickOptions">
					<div class="slick-slide">
						<img src="../../assets/hero1.jpg" alt="The Plough" />
					</div>
					<div class="slick-slide">
						<img src="../../assets/hero2.jpg" alt="The Plough" />
					</div>
					<div class="slick-slide">
						<img src="../../assets/hero3.jpg" alt="The Plough" />
					</div>
				</slick>
		</BaseBox>
	</section>
</template>

<script>
	import BaseBox from "../base/BaseBox";

	export default {
		name: "Hero",
		components: {BaseBox},
		data() {
			return {
				slickOptions: {
					arrows: false,
					loop: true,
					autoplay: true,
					autoplaySpeed: 5000,
					slidesToShow: 1,
					swipe: true
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#hero {
		.slick-slide {
			width: 100%;
			max-height: 600px;
			text-align: center;

			img {
				margin: 0 auto;
				width: 100%;
				height: auto;
				max-width: 1200px;
				max-height: 600px;
			}
		}
	}
</style>