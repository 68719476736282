<template>
	<section id="about">
		<BaseBox>
			<b-container>
				<h3>Have a wedding, birthday party, wake, anniversary, christening…</h3>

				<h3>Ask for details about the use of our free function room</h3>

				<h3>We can arrange a DJ, buffet, sit down meal</h3>
			</b-container>
		</BaseBox>
	</section>
</template>

<script>
	import BaseBox from "../base/BaseBox";
	export default {
		name: "About",
		components: {BaseBox}
	}
</script>

<style scoped lang="scss">
	#about {
		text-align: center;
		margin: 40px 0 60px 0;

		h3 {
			margin-bottom: 20px;
		}
	}
</style>