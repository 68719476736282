<template>
	<ul>
		<li>The Plough</li>
		<li>100 St Johns Road</li>
		<li>Swalecliffe</li>
		<li>Whitstable</li>
		<li>CT5 2RN</li>
	</ul>
</template>

<script>
	export default {
		name: "Address"
	}
</script>

<style scoped lang="scss">
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
</style>