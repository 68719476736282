<template>
	<div class="telephone" :class="centered ? 'centered' + (color !== '' ? ' telephone--' + color : ''): (color !== '' ? 'telephone--' + color : '')">
		<i class="fas fa-phone-alt"></i>&nbsp; 01227 794636
	</div>
</template>

<script>
	export default {
		name: "Telephone",

		props: ['centered','color']
	}
</script>

<style scoped lang="scss">
	.telephone {
		display: flex;
		align-items: center;

		&--pink {
			color: map-get($palette, 'red');
		}

		&.centered {
			justify-content: center;
			margin: 20px 0;
			@include font-rhythm(24);
		}

		i {
			margin-right: 10px;
		}
	}
</style>
