<template>
    <MasterLayout>
        <Hero/>
        <Covid/>
        <Curator/>
        <About/>
    </MasterLayout>
</template>

<script>
    import MasterLayout from '../layouts/MasterLayout';
    import Covid from "../../panels/Covid";
    import Curator from "../../panels/Curator";
    import Hero from "../../panels/Hero";
    import About from "../../panels/About";

    export default {
        components: {
            About,
            Curator,
            Hero,
            MasterLayout,
            Covid
        }
    }
</script>