<template>
    <div id="master-layout"
         :class="classes"
    >
        <ThePageHeader />

        <ThePageContent>
            <slot></slot>
        </ThePageContent>

        <ThePageFooter />
    </div>
</template>

<script>
    import ThePageHeader from '../partials/ThePageHeader';
    import ThePageFooter from '../partials/ThePageFooter';
    import ThePageContent from '../partials/ThePageContent';

    export default {
        components: {
            ThePageHeader,
            ThePageFooter,
            ThePageContent
        },

        props: {
            theme: Number
        },

        computed: {
            classes() {
                let classes = [];

                if (this.theme) {
                    classes.push(`master-layout--theme-${this.theme}`);
                }

                return classes;
            }
        }
    }
</script>

<style lang="scss">

</style>