<template>
	<div class="book-online" :class="centered ? 'centered' + (color !== '' ? ' book-online--' + color : ''): (color !== '' ? 'book-online--' + color : '')">
		<a href="https://web.dojo.app/create_booking/vendor/Tw3LPqF5PXIzXXCBB-Y7Pv-56ERFBAh6Cd1Tb1y00mQ_restaurant" target="_blank"><i class="fas fa-edit"></i>Book online</a>
	</div>
</template>

<script>
	export default {
		name: "Telephone",

		props: ['centered','color']
	}
</script>

<style scoped lang="scss">
	.book-online {
		display: flex;
		align-items: center;

		a {
			color: map-get($palette, 'gold');
		}

		&--pink {
			a {
				color: map-get($palette, 'red');
			}
		}

		&--black {
			a {
				color: map-get($palette, 'base');
			}
		}

		&.centered {
			justify-content: center;
			margin: 20px 0;
			@include font-rhythm(24);
		}

		i {
			margin-right: 1rem;
		}
	}
</style>
