import HomePage from './components/views/templates/HomePage';

const routes = [
    {
        name: 'home',
        path: '/',
        component: HomePage
    },
];

export default routes;