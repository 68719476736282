<template>
	<div class="brands" :class="aligned === 'left' ? 'brands--left' : ''">
		<a href="https://www.facebook.com/theploughswalecliffe1/" target="_blank"><i class="fab fa-facebook"></i></a>
		<a href="https://twitter.com/ThePlough13" target="_blank"><i class="fab fa-twitter"></i></a>
		<a href="https://www.instagram.com/theplough2019/" target="_blank"><i class="fab fa-instagram"></i></a>
	</div>
</template>

<script>
	export default {
		name: "SocialBrands",
		props: ['aligned']
	}
</script>

<style scoped lang="scss">
	.brands {
		a {
			color: map-get($palette, 'gold');
			@include font-rhythm(40);
			line-height: 0;
			margin-left: 20px;

			&:hover, &:active {
				color: map-get($palette, 'tussock');
			}

			i {
				width: 40px;
				height: 40px;
			}
		}

		&--left {
			a {
				margin-left: 0;
				margin-right: 20px;
			}
		}
	}
</style>