<template>
    <header id="page-header">
        <BaseBox background-colour="black" text-colour="gold">
            <b-container>
                <div class="header">
                    <b-row>
                        <b-col
                            cols="12"
                            class="logo text-center"
                            order="1"
                        >
                            <img src="../../../assets/logo.svg" alt="The Plough">
                        </b-col>

                        <b-col
                                cols="4"
                                class="telephone"
                                order="2"
                        >
                            <Telephone />
                        </b-col>

                        <b-col
                            cols="8"
                            class="social"
                            order="2"
                        >
                            <SocialBrands />
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </BaseBox>
    </header>
</template>

<script>
    import BaseBox from "../../base/BaseBox";
    import Telephone from "../../panels/stubs/Telephone";
    import SocialBrands from "../../panels/stubs/SocialBrands";

    export default {
        components: {
            SocialBrands,
            Telephone,
            BaseBox,
        }
    }
</script>

<style lang="scss">
    #page-header {
        .header {
            padding: 20px 10px 10px 10px;

            .row {
                position: relative;
                flex-direction: column;
                height: 160px;

                .logo {
                    img {
                        width: 100%;
                        max-width: 400px;
                        height: auto;
                    }
                }

                .telephone {
                    position: absolute;
                    top: 57px;
                    left: 0;
                    white-space: nowrap;
                    display: flex;
                    height: 40px;
                    align-items: center;
                    font-family: 'Lora', "Palatino Linotype", "Book Antiqua", Palatino, Georgia, serif;
                }

                .social {
                    position: absolute;
                    top: 110px;
                    right: 0;
                    text-align: right;
                    white-space: nowrap;
                }
            }
        }
    }
</style>