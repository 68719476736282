<template>
	<section id="menu">
		<BaseBox background-colour="silver">
			<b-container>
				<b-row class="details details--top">
					<b-col>
						<h4>We're pleased to announce that we are firing up our Kitchen this Sunday to offer a delicious Roast</h4>

						<p>After a successful launch last week, we will offering a choice of either Roast Beef, Roast Chicken or a vegetarian option<br> all served with roast potatoes, yorkshire pudding, creamy leaks, cabbage, peas and carrots</p>

						<p>Fancy a dessert? for an extra £2 we can offer Cheesecake, Lemon fudge cake or Grasshopper cake</p>

						<p>This week you can add a bottle of wine for £10 or 4 bottles of beer for £8</p>

						<p class="tilda text-center"><img src="../../assets/tilda.svg" alt="~"></p>

						<p>Please call us on Thursday's between 1.30pm - 5pm to book your dinner or <a href="https://web.dojo.app/create_booking/vendor/Tw3LPqF5PXIzXXCBB-Y7Pv-56ERFBAh6Cd1Tb1y00mQ_restaurant" target="_blank">click here to book online</a></p>

						<Telephone :centered="true"/>

						<BookOnline :centered="true" color="black"/>

						<p class="tilda text-center"><img src="../../assets/tilda.svg" alt="~"></p>

						<div class="details">
							<p>Dinners will be available between 1pm - 3pm on Sunday for delivery or collection, times will be allocated at time of booking.</p>
							<p>Social distancing rules will apply for both delivery and collection.</p>
						</div>

					</b-col>
				</b-row>
			</b-container>
		</BaseBox>
	</section>
</template>

<script>
	import BaseBox from "../base/BaseBox";
	import Telephone from "./stubs/Telephone";
	import BookOnline from "./stubs/BookOnline";

	export default {
		name: "Covid",

		data: () => {
			return {

			}
		},
		components: {Telephone, BaseBox,BookOnline}
	}
</script>

<style scoped lang="scss">
	#menu {
		.container {
			padding-top: 20px;
			padding-bottom: 20px;

			.tilda {
				margin-bottom: 30px;

				img {
					width: 60px;
					height: auto;
				}
			}

			.details {
				text-align: center;

				&--top {
					margin-bottom: 20px;
				}

				a {
					color: map-get($palette, 'tussock');
				}
			}
		}
	}
</style>
