<template>
	<div id="curatorio">
		<BaseBox background-colour="white">
			<b-container>
				<div id="plough-feed"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
			</b-container>
		</BaseBox>
	</div>
</template>

<script>
	import BaseBox from "../base/BaseBox";
	export default {
		name: "Curator",

		components: {BaseBox},

		mounted(){
			let i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;

			i.src = "https://cdn.curator.io/published/ad2d2b63-7944-456a-8df4-6602994ef444.js";
			e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
		}
	}
</script>

<style scoped lang="scss">
	#curatorio {
		@include font-rhythm(12);

		.crt-logo {
			visibility: hidden;
			opacity: 0;
		}
	}
</style>
