// Styles
import './styles/app.scss';

// Js
import Vue from './providers/vue';
import App from './App.vue';
import router from './providers/router';

// Vue instance
new Vue({
    router,
    render: h => h(App),
}).$mount('#app');