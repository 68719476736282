<template>
    <footer id="footer">
        <BaseBox background-colour="black" text-colour="gold">
            <b-container>
                <b-row>
                    <b-col md="7">
                        <b-row>
                            <b-col md="6" class="mb-4 mb-sm-0">
                                <h4>Contact us</h4>
                                <Address />
                                <br>
                                <Telephone />
                                <br>
                                <BookOnline />
                                <br><br>
                                <SocialBrands aligned="left"></SocialBrands>
                            </b-col>

                            <b-col md="6" class="times">
                                <h4 class="mb-4">Opening Times</h4>

                                <h5>Pub</h5>
                                <p>
                                    <span>Mon - Thur</span> 11:30 - 23:00<br>
                                    <span>Fri - Sat</span> 11:30 - 00:00<br>
                                    <span>Sun</span> 11:30 - 22:00<br>
                                </p>

                                <h5>Restaurant</h5>
                                <p>
                                    <span>Mon</span> Closed<br>
                                    <span>Tue - Fri</span> 12:00 - 20:00<br>
                                    <span>Sat</span> 12:00 - 19:00<br>
                                    <span>Sun</span> 12:00 - 17:00<br>
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="5" class="mb-4 mb-sm-0">
                        <a href="https://g.page/the-plough-2019?share" target="blank">
                            <img src="../../../assets/map.png" alt="Map">
                        </a>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        &copy; The Plough 2020
                    </b-col>
                </b-row>
            </b-container>
        </BaseBox>
    </footer>
</template>

<script>
    import BaseBox from "../../base/BaseBox";
    import Address from "../../panels/stubs/Address";
    import Telephone from "../../panels/stubs/Telephone";
    import BookOnline from "../../panels/stubs/BookOnline";
    import SocialBrands from "../../panels/stubs/SocialBrands";

    export default {
        components: {SocialBrands, Telephone, Address, BaseBox, BookOnline}
    }
</script>

<style scoped lang="scss">
    #footer {
        font-family: 'Lora', "Palatino Linotype", "Book Antiqua", Palatino, Georgia, serif;

        .container {
            padding-top: 20px;
            padding-bottom: 20px;

            .times {
                span {
                    display: inline-block;
                    width: 90px;
                    font-weight: bold;
                }
            }

            img {
                border: 1px solid map-get($palette, 'gold');
                width: 100%;
                height: auto;
            }
        }
    }
</style>
