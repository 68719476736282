<template>
    <div
        class="box"
        :class="classes"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            backgroundColour: String,
            textColour: String,
            padding: String,
        },

        computed: {
            classes() {
                let classes = [];

                if (this.backgroundColour) {
                    classes.push(`box--background-${this.backgroundColour}`)
                }

                if (this.textColour) {
                    classes.push(`box--text-${this.textColour}`);
                }

                if (this.padding) {
                    switch (this.padding) {
                        case 'small':
                            classes.push(`p-5`);
                            break;

                        case 'medium':
                            classes.push(`p-6`);
                            break;

                        case 'large':
                            classes.push(`p-7`);
                            break;
                    }
                }

                return classes;
            }
        }
    }
</script>

<style lang="scss">
    .box {
        @each $name, $hex in $palette {
            &--background-#{$name} {
                background-color: $hex;
            }
        }

        @each $name, $hex in $palette {
            &--text-#{$name} {
                color: $hex;
            }
        }

        & > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>